import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/categoria/:id',
    name: 'Categoria',
    component: () => import('../views/CategoryView.vue')
  },
  {
    path: '/producto/:id',
    name: 'Producto',
    component: () => import('../views/ItemView.vue')
  },
  {
    path: '/ofertas',
    name: 'Ofertas',
    component: () => import('../views/LatestAndSaleView.vue')
  },
  {
    path: '/novedades',
    name: 'Novedades',
    component: () => import('../views/LatestAndSaleView.vue')
  },
  {
    path: '/sobre-nosotros',
    name: 'Sobre Nosotros',
    component: () => import('../views/AboutUsView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/order',
    name: 'corder',
    component: () => import('../views/Order.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: function () {
      return import('../views/404.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        setTimeout( ()=>{
          return resolve(savedPosition);
        }, 0)
      } else {
        return resolve({ top: 0 });
      }
    })
  }
})

export default router

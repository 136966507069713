
export default {
  name: "iconComponent",
  props: {
    iconImg: String,
    color: String,
    size: {
      type: String,
      default: 'small'
    }
  }
};


export default {
  name: "BannerComponent",
  props:{
    image: {
      type: String
    },
    data: {
			type: Object
    },
    showButton: {
      type: Boolean,
      default: true
    },
    showText:{
      type: Boolean,
      default: true
    }
  }
};

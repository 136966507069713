
//This Component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SideBar',
    props:{
        isOpen: Boolean
    }
})

import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "banner w-full aspect-[3/4] bg-gray-300 animate-pulse rounded-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.image != null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "banner w-full aspect-[3/4] bg-gray-300 bg-cover bg-center rounded-md shadow-xl shadow-gray-100 overflow-hidden",
        style: _normalizeStyle(($props.image) ? { backgroundImage: 'url(' + $props.image + ')' } : '')
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4))
    : (_openBlock(), _createElementBlock("div", _hoisted_1))
}

import { defineComponent } from 'vue'

export default defineComponent({
    name: "ProductCard",
    props: {
        name: String,
        price: {
            type: Number,
            default: 0
        },
        sale_price: {
            type: Number,
            default: 0
        },
        image: String,
    },
    methods: {
        formatPrice(price: number) {
            let localeString = 'es-PY'
            let formattingOptions = {
                style: 'currency',
                currency: 'PYG',
                minimumFractionDigits: 0,
            }

            let formattedPrice = new Intl.NumberFormat(localeString, formattingOptions)
            formattedPrice = formattedPrice.format(price) as any

            return formattedPrice.toString()
        }
    }
})

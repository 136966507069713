import { createStore } from 'vuex'

export default createStore({
  state: {
    order: {
      status: 'pending',
      products: [] as Array<any>,
      total: 0,
    }
  },
  getters: {
  },
  mutations: {
    addToCart(state, product){
      //create product object
      const item = {
        name: product.name,
        code: product.code,
        price: (product.sale_price > 0) ? product.sale_price : product.price,
        quantity: 1,
        total: (product.sale_price > 0) ? product.sale_price : product.price,
        image: product.gallery[0].directus_files_id.data.thumbnails[7].url
      }

      //sum total 
      state.order.total+= item.price

      //push to order
      state.order.products.push(item)
    },
    removeFromCart(state, index){
      state.order.total-= (state.order.products[index] as any).price
      state.order.products.splice(index,1)
    },
    emptyCart(state){
      state.order.status= 'pending'
      state.order.products = []
      state.order.total = 0
    }
  },
  actions: {
  },
  modules: {
  }
})


import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: "CategoryDropdown",
    data(){
        return{
            showDropdown: false
        }
    },
    emits: ['optionClicked'],
    components:{
        Icon
    },
    props:{
        mode: String,
        text: String,
        options: {
            type: Array,
            default: [] as any[any]
        }
    },
    methods:{
        toggleDropdown(){
            this.showDropdown =!this.showDropdown;
        },
        optionClicked(){
            this.$emit('optionClicked')
        }
    }
})

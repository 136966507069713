
//custom components
import NavBar from '@/components/NavBar.vue'
import SearchBar from '@/components/SearchBar.vue'
import SideBar from '@/components/SideBar.vue'
import Icon from '@/components/Icon.vue'
import MenuDropdown from '@/components/MenuDropdown.vue'
import { defineComponent } from 'vue'

//Axios
import axios from "axios";

//AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

export default defineComponent({
  name: 'HomeView',
  components: {
    NavBar,
    SearchBar,
    SideBar,
    Icon,
    MenuDropdown
  },
  data() {
    return {
      menuOptions: [
        {
          text: 'Inicio',
          link: '/',
          icon: '/img/icons/home.svg'
        },
        {
          text: 'Categorías',
          link: '',
          icon: '/img/icons/category.svg',
          suboptions: []
        },
        {
          text: 'Novedades',
          link: '/novedades',
          icon: '/img/icons/star.svg'
        },
        {
          text: 'Ofertas',
          link: '/ofertas',
          icon: '/img/icons/offer.svg'
        },
        {
          text: 'Sobre Nosotros',
          link: '/sobre-nosotros',
          icon: '/img/icons/info.svg'
        }
      ],
      sideBarOpen: false,
      config: [] as any[any]
    }
  },
  async created() {
    const config = await axios.get('https://admin.alamomuebles.com.py/api/items/config/1')
    this.config = config.data.data

    const categories = await axios.get('https://admin.alamomuebles.com.py/api/items/categories?fields=*')
    this.menuOptions[1].suboptions = categories.data.data

    this.$nextTick(() => {
      AOS.init()
    })
  },
  methods: {
    toggleSideBar() {
      this.sideBarOpen = !this.sideBarOpen
    }
  }
})
